.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.top-section {
  text-align: center; /* Aligns content to the center horizontally */
}

.top-section img {
  max-width: 100%; /* Ensures image is responsive */
  height: auto; /* Maintains aspect ratio */
  margin-bottom: 30px;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mic-button {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #1a4eea;
  border: none;
  outline: #f3f6f3;
  cursor: pointer;
}

.mic-button.recording {
  background-color: #00ff00;
}

.mic-button.finished {
  background-color: #1a4eea;
  border: 6px solid #00ff00;
}